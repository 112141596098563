/**
 * Pour la gestion de la page faq.php
 * @author Aurélien Renault
 * @since 2019/03/08
 */

/**
 * Mise en pace des event
 */
function faqinit() {
    $('#theme_global .theme').on('click', function() {
        var id = $(this).data('id');
        var theme = $('#theme_page');
        $('#theme_global').removeClass('actif');
        $('.title_detail_sub').css('display','none');
        theme.addClass('actif');
        showTabFaq(id, 1)
    });

    $('#theme_page .theme').on('click', function() {
        var id = $(this).data('id');
        showTabFaq(id, 1);
    });
}


function showTabFaq(node_id, level, question_id) {
    if (question_id !== null && question_id !== undefined) {
        //show answer
        $(".faq_reponse:not(.faq_reponse_" + question_id + ")").slideUp(
            "fast",
            function() {
                $(this).addClass("cache");
            }
        );

        $(".faq_reponse_" + question_id).slideToggle("slow", function() {
            $(this).toggleClass("cache");
        });

        $(".faq_question:not(.faq_question_" + question_id + ")").removeClass(
            "active"
        );
        $(".faq_question_" + question_id).toggleClass("active");
    } else {
        var parent_id = $(".faq_theme_" + node_id).data("parent");

        //active
        $(".faq_element:not(.faq_theme_" + node_id + ")").removeClass("active");
        $(".faq_theme_" + node_id).toggleClass("active");
        $(".faq_theme_" + parent_id).toggleClass("active");
        $(".theme_menu .theme[data-id= "+node_id+"]").toggleClass("active");

        //show questions
        $(
            ".faq_questions:not(.faq_questions_" +
            node_id +
            ", .faq_questions_" +
            parent_id +
            " )"
        ).slideUp("fast", function() {
            $(this).addClass("cache");
        });

        $(".faq_questions_" + node_id).slideToggle("slow", function() {
            $(this).toggleClass("cache");
        });

        // Hide other node of same level
        $(".faq_level_" + level + ":not(.faq_theme_" + node_id + ")").each(
            function(idx) {
                var id = $(this).data("id");
                $(".faq_level_parent_" + id).slideUp("fast", function() {
                    $(".faq_level_parent_" + id)
                        .addClass("cache")
                        .trigger("classChange");
                });
            }
        );

        // Show/hide child of selected node
        $(".faq_level_parent_" + node_id).slideToggle("slow", function() {
            $(".faq_level_parent_" + node_id)
                .toggleClass("cache")
                .trigger("classChange");
        });

        $(".faq_element").on("classChange", function() {
            var id = $(this).data("id");

            if ($(this).hasClass("cache")) {
                $(".faq_level_parent_" + id)
                    .addClass("cache")
                    .trigger("classChange");
            }
        });
    }
}