$(function () {

    "use strict";

    var header = document.getElementById("site_head_wrap");
    var search = document.getElementById("search_home");
    var shade = document.getElementById("shad_menu");

    function onWinScroll() {

        var scrollTop = $(document).scrollTop();

        if (header.classList.contains("sticky")) {

            if (scrollTop === 0) {

                header.classList.remove("sticky");
                shade.classList.remove("sticky");
            }
        } else {

            if (scrollTop !== 0) {

                header.classList.add("sticky");
                shade.classList.add("sticky");
            }
        }
    }

    function onSearchStateChange(evt) {

        if (evt.type === "focus") {

            search.parentElement.classList.add("searching");
        } else if (search.value.trim() === "") {

            search.parentElement.classList.remove("searching");
        }
    }

    $(window).on("scroll", onWinScroll);
    $(search).on({focus: onSearchStateChange, blur: onSearchStateChange});

    onWinScroll();

    // Initialisation du slider si présent
    var swiperContainer = document.getElementById('homeSwiper');

    if (swiperContainer) {
        var homeSwiper = new Swiper('#homeSwiper', {
            slidesPerView: 2,
            spaceBetween: 15,
            loop: true,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
            },
            breakpoints: {
                // when window width is >= 640px
                1024: {
                    slidesPerView: 4,
                    spaceBetween: 20,
                }
            }
        });
    }
});